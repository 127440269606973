import validator from './validate'

let userRules={
    telephone:[{required: true, message: '手机号不能为空', trigger: ['blur']},
        {pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号', trigger: 'blur'}],
    mesCode:[{required: true, message: '验证码不能为空', trigger: ['blur']}],
    password:[
        {required: true, message: '密码不能为空', trigger: ['blur']},
        // {pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,15}$/,message: '请输入8-20位密码，密码需包含数字、字母、特殊字符3种类型，字母区分大小写',trigger: 'blur'},],
        {pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[._!@#$%^&*`~()-+=<>])[A-Za-z0-9._!@#$%^&*`~()-+=<>]{8,20}$/,message: '请输入8-20位密码，密码需包含数字、字母、特殊字符3种类型，字母区分大小写',trigger: 'blur'},],
    rePassword:[{required: true, message: '密码不能为空', trigger: ['blur']},
        {pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[._!@#$%^&*`~()-+=<>])[A-Za-z0-9._!@#$%^&*`~()-+=<>]{8,20}$/,message: '请输入8-20位密码，密码需包含数字、字母、特殊字符3种类型，字母区分大小写',trigger: 'blur'},],
}
let loginRules={
    username:[ {required: true, message: '手机号不能为空', trigger: 'blur'},
        {pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号', trigger: 'blur'},
    ],
    password:[
        {required: true, message: '密码不能为空', trigger: ['blur']},
        {pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[._!@#$%^&*`~()-+=<>])[A-Za-z0-9._!@#$%^&*`~()-+=<>]{8,20}$/,message: '请输入8-20位密码，密码需包含数字、字母、特殊字符3种类型，字母区分大小写',trigger: 'blur'},],
}

let remindRules = {
    timingCause:[{required: true, message: '关闭原因不能为空', trigger: ['blur', 'change']},
    ],
    onTime:[{required: true, message: '开启时间不能为空', trigger: ['blur', 'change']}],
    offTime:[{required: true, message: '关闭时间不能为空', trigger: ['blur', 'change']}],
}
let administratorRules={
    name:[{required: true, message: '姓名不能为空', trigger: ['blur', 'change']},
        {pattern: /^[^\s]*$/, message: '姓名内不能包含空格', trigger: ['blur', 'change']},
        {min: 1, max: 6, message: '长度在6位以内', trigger: 'blur'}],
    username:[ {required: true, message: '手机号不能为空', trigger: ['blur', 'change']},
        {pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号', trigger:['blur', 'change']},
    ],
}
let roleRules={
    roleName:[{required: true, message: '角色名称不能为空', trigger: ['blur', 'change']},
        {pattern: /^[^\s]*$/, message: '角色名称不能包含空格', trigger: ['blur', 'change']},
        {min: 1, max: 12, message: '长度在12位以内', trigger: 'blur'}],
    description:[ {min: 1, max: 50, message: '长度在50位以内', trigger: 'blur'}]
}
let menuRules={
    menuName:[{required: true, message: '菜单名称不能为空', trigger: ['blur', 'change']}],
    menuCode:[{required: true, message: '菜单编码不能为空', trigger: ['blur', 'change']}],
    status:[{required: true, message: '状态不能为空', trigger: ['blur', 'change']}],
    parentId:[{required: true, message: '上级菜单不能为空', trigger: ['blur', 'change']}],
    type:[{required: true, message: '菜单类型不能为空', trigger: ['blur', 'change']}],
}
let cementRules={
    custType:[{required: true, message: '客户类别不能为空', trigger: ['blur', 'change']}],
    custCode:[{required: true, message: '客户名称不能为空', trigger: ['blur', 'change']}],
    transId:[{required: true, message: '发货企业不能为空', trigger: ['blur', 'change']}],
    orgId:[{required: true, message: '销售组织不能为空', trigger: ['blur', 'change']}],
}
let materialRules={
    name:[{required: true, message: '物料名称不能为空', trigger: ['blur', 'change']}],
    extName:[{required: true, message: '货物劳务服务不能为空', trigger: ['blur', 'change']}],
}
export {
    loginRules,
    userRules,
    remindRules,
    roleRules,
    administratorRules,
    menuRules,
    cementRules,
    materialRules
}
