import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isScroll: false,
        userName:'0',
        customerInfoSetting: 0,//客户信息
        cementCustomerSetting:0,//水泥网价客户
        materialManageSetting:0,//货物劳务物料
        organizeAndCompanySetting:0,//销售组织与发货企业
        appInvoiceSetting:0,//app开票
        ncInvoiceSetting:0,//nc开票
        receivableManageSetting:0,//应收单
        administratorManageSetting:0,
        roleManageSetting:0,
        menuManageSetting:0,//菜单管理
        menuFunManageSetting:0,//菜单功能管理
        roleList:[],
    },
    getters: {
        scrollget(state) {
            return state.isScroll
        }
    },
    mutations: {
        scrollSet(state, payload) {
            state.isScroll = payload;
        },
        customerInfoSet(state, payload) {
            state.customerInfoSetting = payload;
        },
        cementCustomerSet(state, payload) {
            state.cementCustomerSetting = payload;
        },
        materialManageSet(state, payload) {
            state.materialManageSetting = payload;
        },
        organizeAndCompanySet(state, payload) {
            state.organizeAndCompanySetting = payload;
        },
        appInvoiceSet(state, payload) {
            state.appInvoiceSetting = payload;
        },
        ncInvoiceSet(state, payload) {
            state.ncInvoiceSetting = payload;
        },
        receivableManageSet(state, payload) {
            state.receivableManageSetting = payload;
        },
        administratorManageSet(state, payload) {
            state.administratorManageSetting = payload;
        },
        roleManageSet(state, payload) {
            state.roleManageSetting = payload;
        },
        setRoleList(state,payload){
            state.roleList=payload
        },
        menuUpdate(state,payload){
            state.menuManageSetting=payload;
        },
        menuFunUpdate(state,payload){
            state.menuFunManageSetting=payload;
        } ,
    },
    actions: {},
    modules: {}
})
