import Filter from './filter'
// 一行一条，filter 移动到独立的文件中处理
export default {
    gate: 'receipt',
    version: '', // 默认版本
    module: '', // 默认模块
    key: '', // 设置发送数据的类型是JSON还是BLOB
    filter: function () {}, // 默认拦截过滤器
    // inter: function () {}, // 默认拦截器
    list: {
        /**用户接口 */
        user:{
            login:{ module: '', url: '/login',type:'post',filter:Filter.saveUser}, //登录
            logout:{ module: '', url: '/logout',type:'get',contentType:'application/x-www-form-urlencoded'}, //登出
            userInfo:{ module: '', url: '/info',type:'get',contentType:'application/x-www-form-urlencoded'}, //用户信息
            getCode:{ module: '', url: '/send_code',type:'Post',}, //获取验证码
            verifyCode:{ module: '', url: '/verify_code',type:'Post',}, //验证验证码
            resetPassword:{ module: '', url: '/change_password',type:'Post',}, //修改密码
            forgetPassword:{ module: '', url: '/change_password_no_login',type:'Post',}, //忘记密码
        },

        invoice:{
            roleMenu:{ module: '', url: '/menu/listByRoleIds',type:'get',},//用户菜单
            customerList:{ module: '', url: '/customer/query',type:'post',contentType:'application/x-www-form-urlencoded'},//客户信息
            financeList:{ module: '', url: '/customer/query/fi',type:'post',contentType:'application/x-www-form-urlencoded'},//财务信息
            organizeList:{ module: '', url: '/bdOrg/pageList',type:'post',contentType:'application/x-www-form-urlencoded'},//销售组织列表
            getOrganize:{ module: '', url: '/bdOrg/queryByOrgName',type:'get',},//销售组织列表
            getCompany:{ module: '', url: '/bdOrg/queryByTransName',type:'get',},//发货企业列表
            getFactory:{ module: '', url: '/bdOrg/findFactoryByOrgId',type:'get',},//发货厂区

            allSwitch:{ module: '', url: '/switchStatus/updateGlobal',type:'post',contentType:'application/x-www-form-urlencoded'},//全局开关
            switchShow:{ module: '', url: '/switchStatus/queryGlobal',type:'get',},//全局开关回显
            switchOrg:{ module: '', url: '/switchStatus/updateOne',type:'post',contentType:'application/x-www-form-urlencoded'},//单个销售组织开关
            setRegular:{ module: '', url: '/bdOrg/updateMerge',type:'post',contentType:'application/x-www-form-urlencoded'},//修改合单规则
            cementPriceList:{ module: '', url: '/netprice/pageList',type:'get'},//水泥网价
            cementDelete:{ module: '', url: '/netprice/delete',type:'POST',contentType:'application/x-www-form-urlencoded'},//水泥网价删除
            cementAdd:{ module: '', url: '/netprice/myAdd',type:'POST',contentType:'application/x-www-form-urlencoded'},//水泥网价新增
            cementEdit:{ module: '', url: '/netprice/myEdit',type:'POST',contentType:'application/x-www-form-urlencoded'},//水泥网价修改
            materialList:{ module: '', url: '/material/pageList',type:'get'},//劳务物料
            materialAdd:{ module: '', url: '/material/add',type:'post'},//劳务物料添加
            materialDel:{ module: '', url: '/material/delete',type:'post',contentType:'application/x-www-form-urlencoded'},//劳务物料删除
            materialEdit:{ module: '', url: '/material/edit',type:'post'},//劳务物料修改

            billList:{ module: '', url: '/recBill/pageList',type:'get'},//应收单
            appBillList:{ module: '', url: '/appBillApply/pageList',type:'post',contentType:'application/x-www-form-urlencoded'},//app开票申请
            factoryList:{ module: '', url: '/customer/factoryList',type:'get'},//厂区列表
            orgList:{ module: '', url: '/customer/queryOrg',type:'get'},//组织列表
            applyList:{ module: '', url: '/ncBillApply/pageList',type:'Post',contentType:'application/x-www-form-urlencoded'},//NC开票申请单
            materialPageList:{ module: '', url: '/ncBillApply/materPageList',type:'Post',contentType:'application/x-www-form-urlencoded'},//NC开票申请单
            materList:{ module: '', url: '/material/queryMater',type:'Post',contentType:'application/x-www-form-urlencoded'},//物料分页查询
            getMenuList:{ module: '', url: '/menu/list',type:'get'},//菜单列表
            userList:{ module: '', url: '/user/pageList',type:'get'},//管理员列表
            delUser:{ module: '', url: '/user/delete',type:'post',contentType:'application/x-www-form-urlencoded'},//管理员删除
            editUser:{ module: '', url: '/user/edit',type:'post',},//管理员编辑
            addUser:{ module: '', url: '/user/add',type:'post',},//管理员新增
            resetPassword:{ module: '', url: '/change_password',type:'post',},//重置密码
            selectRoleList:{ module: '', url: '/role/list',type:'get'},//角色查询列表
            roleList:{ module: '', url: '/role/pageList',type:'get'},//角色权限列表
            addRole:{ module: '', url: '/role/add',type:'post'},//角色新增
            editRole:{ module: '', url: '/role/edit',type:'post',},//角色编辑
            delRole:{ module: '', url: '/role/delete',type:'post',contentType:'application/x-www-form-urlencoded'},//角色权限删除
            menuPageList:{ module: '', url: '/menu/pageList',type:'get'},//菜单分页
            menuAdd:{ module: '', url: '/menu/add',type:'Post'},//菜单添加
            menuEdit:{ module: '', url: '/menu/edit',type:'Post'},//菜单编辑
            menuDel:{ module: '', url: '/menu/delete',type:'Post',contentType:'application/x-www-form-urlencoded'},//菜单删除
        }
    }
}
